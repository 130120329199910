import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { PrimaryLink } from "components/buttons";

import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/blob/svg-decorator-blob.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/blob/svg-decorator-blob.svg";
import { useScrollOfHeaderButton } from "helpers/ScrollToTop";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

// const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
// const SwitchButton = styled.button`
//   ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
//   ${props => props.active && tw`bg-primary-500 text-gray-100`}
// `;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-start px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${({ bgColor }) => {
    if (bgColor === '200')
      return tw`bg-primary-200`
      if (bgColor === '300')
      return tw`bg-secondary-300`
      if (bgColor === '400')
      return tw`bg-blue-400`

    return tw`bg-indigo-500`
  }}

  ${tw`h-48 items-start pt-10 flex flex-col leading-relaxed -mx-8 px-4 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-white text-sm font-medium tracking-wide`}
  }
  ${tw`text-white`}
`;

// const PlanFeatures = styled.ul`
//   ${tw`flex flex-col -mx-8 px-4 py-8 flex-1 text-sm`}
//   .feature {
//     ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
//   }
// `;

const PlanFeatures = styled.ul`
  ${tw`my-8 flex-1 lg:-mx-6 -mx-6 sm:-mx-10 py-1 px-1 sm:px-5 lg:p-5 xl:-mx-10 text-left`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-gray-500 tracking-wide ml-2`}
    }
  }
`;

const Icon = styled(CheckboxIcon)`
${({ color }) => {
  console.log('color', color)
  if (color === '200')
    return tw`text-primary-200`
    if (color === '300')
    return tw`text-secondary-300`
    if (color === '400')
    return tw`text-blue-400`

  return tw`text-indigo-500`
}}
`

const PlanAction = tw.div`px-4 pb-8`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-white bg-orange-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

// const Action = styled(PrimaryLink)`
//   ${tw`px-6 py-1 sm:px-4 sm:py-2 lg:px-5 lg:py-2 leading-normal`}
// `

export default ({
  subheading = "",
  heading = "Planos Flexíveis",
  description = "A Medipreço oferece quatro planos distintos, cada um projetado para atender as necessidades específicas dos nossos clientes e proporcionar uma gestão de saúde eficiente",
  plans = null,
  primaryButtonText = "Entre em contato",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Benefício Farmácia",
      durationPrices: ["$0", "$0"],
      mainFeature: "Plano Acesso",
      features: [
        "Aplicativo Medipreço para aquisição de produtos e medicamentos",
        "Plataforma corporativa para que o RH gerencie o benefício com eficácia",
        "Sistema integrado de gestão de saúde",
        "Suporte farmacêutico através do aplicativo para todos os colaboradores",
        "Inclusão de até 5 dependentes sem custo adicional",
        "Integrações personalizadas com o sistema de RH e opção de desconto em folha",
        "Cobrança realizada por colaborador ativo (titular do plano)"
      ],
      featured: true
    },
    {
      name: "Gestão de Subsídio Pós-Pago",
      durationPrices: ["$0", "$0"],
      mainFeature: "Plano Cuidado",
      features: [
        "Todos os benefícios do Plano Acesso, com exceção da inclusão de dependentes",
        "Gestão avançada de subsídio pós-pago, assegurando a eficácia no uso dos recursos",
        "Sistema robusto anti-fraude, para prevenir gastos indevidos",
        "Flexibilidade para compra de medicamentos tanto pelo aplicativo quanto em qualquer farmácia no país, com reembolso simplificado",
        "Taxa estipulada por colaborador (titular do plano)"
      ],
      featured: true,
      bgColor: '200'
    },
    {
      name: "Gestão de Subsídio Pré-Pago",
      durationPrices: ["$49", "$499"],
      mainFeature: "Plano Vida",
      features: [
        "Todos os benefícios dos Planos Acesso e Cuidado, com exceção da inclusão de dependentes e fluxo pós pago",
        "Administração completa do subsídio pré-pago, com a Medipreço assumindo o risco do sinistro",
        "Garantia de um frete grátis mensal para cada usuário cadastrado",
        "Valor fixado por vida coberta, englobando titular e dependentes"
      ],
      featured: true,
      bgColor: '300'
    },
    {
      name: "Solução Sob Medida",
      durationPrices: ["$49", "$499"],
      mainFeature: "Plano Customizado",
      features: [
        "Uma proposta totalmente personalizável, criada para se adaptar às necessidades únicas de cada organização",
        "Liberdade para os clientes selecionarem e combinarem serviços e funcionalidades dos planos Acesso, Cuidado e Vida",
        "Ajuste fino das ferramentas de gestão de saúde, suporte farmacêutico, subsídios e anti-fraude, além de integrações com sistemas internos",
        "Opções de adicionar serviços exclusivos, como programas de bem-estar, análises de dados de saúde avançadas e campanhas de conscientização",
        "Flexibilidade total para que as empresas possam oferecer um pacote que reflete suas prioridades, cultura e objetivos estratégicos de saúde corporativa",
        "Estrutura de preços adaptativa, que se alinha ao escopo dos serviços selecionados, garantindo um investimento proporcional ao valor entregue"
      ],
      featured: true,
      bgColor: '400',
      color: 'white'
    }
  ];

  if (!plans) plans = defaultPlans;

  // const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const ScrollOfHeaderButton = useScrollOfHeaderButton('formsection')

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        {/* <PlanDurationSwitcher>
          {planDurations.map((planDuration, index) => (
            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
          ))}
        </PlanDurationSwitcher> */}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader color={plan.color} bgColor={plan.bgColor}>
                {/* <span className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                  <span className="slash"> / </span>
                  <span className="duration">{planDurations[activeDurationIndex].text}</span>
                </span> */}
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <Icon className="icon" color={plan.bgColor} />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>
              <PlanAction>
                <ActionButton onClick={ScrollOfHeaderButton}>{primaryButtonText}</ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
