import SaaSProductLandingPage from 'demos/SaaSProductLandingPage';
import { PageContextProvider } from 'hooks/pageContext';

export const SaasContext = ({type}) => {
  
  return (
    <PageContextProvider type={type}>
      <SaaSProductLandingPage />
    </PageContextProvider>
  )
}