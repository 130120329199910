import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import PageContext from '../../hooks/pageContext'

const DescriptionContent = tw.div`text-4xl flex flex-row items-end justify-items-start`;
const DescriptionDecoration = styled.div`
  ${tw`w-10 h-2 mr-2 mb-3 rounded`};
  background: ${({toggle}) => toggle}
`
const HeadingDescription = tw.p`text-2.5xl sm:text-3xl lg:text-5xl text-secondary-600 font-thin max-w-md min-w-fc text-left`;

const LineDescription = ({ children, ...props }) => {
  const {textColor} = useContext(PageContext)

  return (
    <DescriptionContent>
      <DescriptionDecoration toggle={textColor}/>
      <HeadingDescription {...props}>{children}</HeadingDescription>
    </DescriptionContent>
  );
};

export default LineDescription;
