import React, { useContext } from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { DecorationBlob } from 'components/misc/DecorationBlob'
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import { PrimaryLink } from "components/buttons";
import PageContext from 'hooks/pageContext';
import { useScrollOfHeaderButton } from 'helpers/ScrollToTop'

const PrimaryBackgroundContainer = styled.div`
  ${tw`py-20 lg:py-24 bg-primary-500 rounded-lg relative`};
  background: ${({toggle}) => toggle}
` 
tw.div`py-20 lg:py-24 bg-primary-500 rounded-lg relative`
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(DecorationBlob)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(DecorationBlob)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`
export default ({
  text = "Empresas de todo Brasil estão usando a Medipreço.",
  pushDownFooter = true,
}) => {
  const { textColor, getStartedButton } = useContext(PageContext)
  const ScrollToFormSection = useScrollOfHeaderButton('formsection')

  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
      <PrimaryBackgroundContainer toggle={textColor}>
        <Row>
          <TextContainer>
            <Text>{text}</Text>
          </TextContainer>
          <LinksContainer>
            <PrimaryLink onClick={ScrollToFormSection}>{getStartedButton}</PrimaryLink>
          </LinksContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>
      </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
