import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import Youtube from "react-youtube";
import { useScrollOfHeaderButton } from "helpers/ScrollToTop";
import SectionTitle from "components/texts/SectionTitle";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import VideoImage1 from '../../images/youtube/video1.jpg'
import VideoImage2 from '../../images/youtube/video2.jpg'
import VideoImage3 from '../../images/youtube/video3.jpg'
import VideoImage4 from '../../images/youtube/video4.jpg'
import VideoImage5 from '../../images/youtube/video5.jpg'
import VideoImage6 from '../../images/youtube/video6.jpg'
import VideoImage7 from '../../images/youtube/video7.jpg'
import { GrayContainer } from "components/misc/Layouts";

export default ({
  heading = "",
  subheading = "",
  description = "",
  lineDescription = "",
}) => {
  const { innerWidth } = window
  const [videoId, setVideoId] = useState('wYLtSO0cHwU')
  const [autoPlay, setAutoPlay] = useState(0)
  const [windowSize, setWindowSize] = useState(innerWidth)
  const ScrollOfHeaderButton = useScrollOfHeaderButton('video')

  const TextVideo = tw.p`mt-3 line-clamp-2 font-bold`

  const VideoContainer = tw.div`
    flex h-auto w-auto items-center justify-center my-10
  `

  const ShortVideosContainer = tw.div`
    flex flex-col lg:flex-row items-center justify-center gap-10 mb-10
  `
  const VideoSpace = tw.div`flex flex-col text-start w-80 sm:w-72 cursor-pointer z-50`

  const ShortVideo = tw.div`w-80 h-48 sm:w-72 sm:h-40 bg-black`

  const VideoImage = tw.img`w-80 h-48 sm:w-72 sm:h-40`

  const Subheading = tw(SubheadingBase)`text-center mb-3`;
  const Description = tw(SectionDescription)`text-center mx-auto`;
  const HeaderContainer = tw.div`flex items-center flex-col mt-20`


  const onClickVideo = (videoId) => {
    setVideoId(videoId)
    setAutoPlay(1)
    ScrollOfHeaderButton()
  }


  useEffect(() => {
    function getWindowWidth () {
      const {innerWidth} = window
      return innerWidth
    }

    function handleWindowResize() {
      setWindowSize(getWindowWidth());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [innerWidth]);

  const opts = {
    height: '780',
    width: '1280',
    playerVars: {
      autoplay: autoPlay,
    },
  };

  const opts2 = {
    height: '195',
    width: '320',
    playerVars: {
      autoplay: autoPlay,
    }
  }

  const videosList = [
    {
      videoId: 'wYLtSO0cHwU',
      videoTitle: 'Saiba como fazer uma compra pelo aplicativo da Medipreço',
      videoImage: VideoImage1,
      videoAlt: 'Video compra pelo App'
    },
    {
      videoId: 'Yop7C5NQNAQ',
      videoTitle: 'Primeira retirada de pedido no armário inteligente da Medipreço',
      videoImage: VideoImage2,
      videoAlt: 'Video retirada de pedido'
    },
    {
      videoId: 'h-QyEvvhOaw',
      videoTitle: 'Instalação e testes do nosso smart locker na cor preta',
      videoImage: VideoImage3,
      videoAlt: 'Video intslação locker'
    },
    {
      videoId: 'iQoX8nz3vq0',
      videoTitle: 'Relato de experiência de uso da Medipreço por uma colaboradora do IESB',
      videoImage: VideoImage4,
      videoAlt: 'Video IESB'
    }
  ]

  const videoList2 = [
    {
      videoId: 'cQLJfMNgnvM',
      videoTitle: 'Como retirar seu pedido no armário inteligente da Medipreço',
      videoImage: VideoImage5,
      videoAlt: 'Video Locker'
    },
    {
      videoId: '0rA3lW67EDY',
      videoTitle: 'Depoimento do Sr. Álvaro sobre a Medipreço',
      videoImage: VideoImage6,
      videoAlt: 'Video Depoimento Sr. Álvaro'
    },
    {
      videoId: 'RcaWOx2MAqQ',
      videoTitle: 'Medipreço e Leroy Merlin | Uso do SmartLocker',
      videoImage: VideoImage7,
      videoAlt: 'Video Leroy Merlin'
    }
  ]

  return (
    <GrayContainer>
    <HeaderContainer id="video">
      {subheading && <Subheading>{subheading}</Subheading>}
      <SectionTitle heading={heading} lineDescription={lineDescription} />
      {description && <Description>{description}</Description>}
    </HeaderContainer>
    {windowSize <= 1000 ? (
          <VideoContainer>
            <Youtube
              videoId={videoId}
              opts={opts2}
            />
          </VideoContainer>
        ) : (
          <VideoContainer>
            <Youtube
              videoId={videoId}
              opts={opts}
            />
          </VideoContainer>
        )}
        <ShortVideosContainer>
          {videosList.map((item) => {
            return (
              <VideoSpace key={item.videoId} onClick={() => onClickVideo(item.videoId)}>
                <ShortVideo>
                  <VideoImage src={item.videoImage} alt={item.videoAlt}/>
                </ShortVideo>
                <TextVideo>{item.videoTitle}</TextVideo>
              </VideoSpace>
            )
          })}
        </ShortVideosContainer>
        <ShortVideosContainer>
          {videoList2.map((item) => {
            return (
              <VideoSpace key={item.videoId} onClick={() => onClickVideo(item.videoId)}>
                <ShortVideo>
                  <VideoImage src={item.videoImage} alt={item.videoAlt}/>
                </ShortVideo>
                <TextVideo>{item.videoTitle}</TextVideo>
              </VideoSpace>
            )
          })}
        </ShortVideosContainer>
    </GrayContainer>
  )
}