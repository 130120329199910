import tw from "twin.macro";
// import styled from "styled-components";

const Link = tw.button`w-full cursor-pointer sm:w-auto text-sm text-center sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-md font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
export const PrimaryLink = tw(Link)`bg-orange-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-start`;
export const ButtonLink = tw.button`text-lg tracking-wide lg:text-base font-semibold px-6 py-3 rounded-md bg-orange-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-center transition duration-300 cursor-pointer`;

// export const SecondaryLink = styled.button`
//   ${tw`w-full cursor-pointer sm:w-auto text-sm text-center sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-md font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline text-gray-100 border-gray-500 hover:bg-gray-100 hover:text-primary-500 hover:border-primary-500`}
//   &:hover {
//     color: ${({toggle}) => toggle};
//     border-color: ${({toggle}) => toggle};
//   }
//   `
