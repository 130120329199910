// import { Document, Page, Image} from '@react-pdf/renderer';
import React from 'react';
import { BackGround, Header, HeaderTitle } from './styles';
import { PDFViewer } from './components/PDFViewer';

export function PDFComponent({ url, title, bgColor }) {
  return (
    <BackGround toggle={bgColor}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
        <PDFViewer
          url={url}
        />
    </BackGround>
  );
}
