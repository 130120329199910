import React, { useContext, useMemo, useState } from 'react';
import PageContext from 'hooks/pageContext';
import { Container, LoadingContainer } from './styles';

export function PDFViewer(props) {
  const [pdfData, setPdfData] = useState(null)
  const  { seePDF } = useContext(PageContext);

  useMemo(() => {
    fetch(props.url)
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob)
      setPdfData(objectUrl)
    })
  }, [props.url])

  const Loading = () => 
    (
      <LoadingContainer>
        <h1>Carregando...</h1>
      </LoadingContainer>
    )
  

  if(!pdfData) {
    return <Loading />
  }

  return (
    <Container>
        <object
          style={{backgroundColor: 'white'}}
          data={pdfData}
          type='application/pdf'
          width='100%'
          height='100%'
          onClick={() => seePDF(props.url)}
        >
          
        </object>
    </Container>
  );
}

