import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Position(obj){
  var currenttop = 0;
  if (obj.offsetParent){
   do{
    currenttop += obj.offsetTop;
   }while ((obj = obj.offsetParent));
   return [currenttop];
   }
 }

export const useScrollOfHeaderButton = (id) => {
  const ScrollOfHeaderButton = () => {
    window.scroll({top: Position(document.getElementById(id)), left: 0, behavior: 'smooth'})
  }
  
  return ScrollOfHeaderButton

}

export const handleBlogPage = () => {
  const url = 'https://blog.medipreco.com.br'
  window.open(url, '_blank').focus();
}

export const handleTermsPage = () => {
  const url = 'https://www.medipreco.com.br/user-agreement.html'
  window.open(url, '_blank').focus();
}

export const handlePoliticPage = () => {
  const url = 'https://www.medipreco.com.br/privacy-policy.html'
  window.open(url, '_blank').focus();
}


 
  