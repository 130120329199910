import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
// import { DecorationBlob } from 'components/misc/DecorationBlob'
import SectionTitle from "components/texts/SectionTitle";
import Carrousel from "components/carrousel";
import ProfileOne from '../../images/profileImages/profile1.jpeg'
import ProfileTwo from '../../images/profileImages/profile2.jpeg'
import ProfileThree from '../../images/profileImages/profile3.jpeg'
import ProfileFour from '../../images/profileImages/profile4.jpeg'
import ProfileFive from '../../images/profileImages/profile5.jpeg'
import ProfileSix from '../../images/profileImages/profile6.jpeg'
// import ProfileSeven from '../../images/profileImages/profile7.jpg'

const TestimonialContainer = tw.div`mt-16 bg-white mx-1 p-1 h-80 rounded-md z-1 max-w-sm`;
const Person = tw.div`flex flex-row items-center justify-end`

const Testimonial = tw.div`text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const Customer = tw.div`mx-5 flex flex-col text-left items-start justify-start`
const CustomerName = tw.p`font-bold text-sm tracking-wide`;
const CustomerPosition = tw.p`text-gray-900 font-thin text-sm tracking-wide`;
const DecoratorText = tw.h1`font-extrabold text-6xl text-primary-100`;

// const DecoratorBlob1 = styled(DecorationBlob)`
//   ${tw`pointer-events-none overflow-hidden !z-40 absolute right-0 left-0 mx-auto top-0 h-96 w-96 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
// `;
// const DecoratorBlob2 = styled(DecorationBlob)`
//   ${tw`pointer-events-none overflow-hidden !z-40 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
// `;

export default ({
  subheading = "nossos clientes?",
  heading = "O que dizem",
  testimonials = [
    {
      imageSrc:
        ProfileOne,
      quote:
        "Nossa. Já no primeiro atendimento fui super bem atendido e as dúvidas eventuais foram plenamente respondidas. Atendimento rápido e confiável",
      customerName: "Vivest",
      alt: "Perfil 1"
      // customerPosition: 'Gerente Executiva'
    },
    {
      imageSrc:
        ProfileTwo,
      quote:
        "O atendimento da Medipreço merece miiiiiiiil. Sempre que preciso eles me atendem super bem. E sempre, sempre resolvem o que preciso com prontidão e sem dor de cabeça. Parabéns 👏",
      customerName: "Record TV",
      alt: "Perfil 2"
      // customerPosition: 'Diretor Executivo'
    },
    {
      imageSrc:
        ProfileThree,
      quote:
        "Praticidade e efetividade. Se a entrega fosse hoje, no feriado, no local de trabalho, não seria lógico. Ao efetuar a confirmação deste processo comigo, vocês acertam e garantem a experiência do cliente. Parabéns.",
      customerName: "BRBCard",
      alt: "Perfil 3"
      // customerPosition: 'Gerente de RH'
    },
    {
      imageSrc:
        ProfileFour,
      quote:
        "Hoje foi a primeira vez que utilizei o app. Tive dúvidas e a atendente foi sensacional. Foi como se estivesse conversando pessoalmente. Por enquanto gostei muito. Parabéns Catherine👏👏",
      customerName: "Vivest",
      alt: "Perfil 4"
    },
    {
      imageSrc:
        ProfileFive,
      quote:
        "Em primeiro lugar o atendimento da atendente Larissa, onde expliquei minha situação e de imediato ela resolveu uma situação de entrega, fez toda diferença obrigado a toda a equipe.",
      customerName: "Grupo Odilon Santos",
      alt: "Perfil 5"
    },
    {
      imageSrc:
        ProfileSix,
      quote:
        "A Medipreco e muito top. Melhores preços descontos e rapidez tanto na entrega quanto na prestação de serviço e na comunicação. Vcs são tops!",
      customerName: "Leroy Merlin",
      alt: "Perfil 6"
    },
    // {
    //   imageSrc:
    //     ProfileSeven,
    //   quote:
    //     "Ótimo atendimento. Atendente super prestativa, resolveu o problema sem burocracia, sem abrir chamado, sem passar pro próximo nível. Tudo simples e direto.",
    //   customerName: "Vivest",
    // }
  ]
}) => {
  return (
    <>
      <ContentWithPaddingXl>
        <SectionTitle heading={heading} lineDescription={subheading} />
        <Carrousel slidesToShow={3}>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Person>
                  <Image src={testimonial.imageSrc} alt={testimonial.alt}/>
                  <Customer>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    {testimonial.customerPosition ? (
                      <CustomerPosition>{testimonial.customerPosition}</CustomerPosition>
                    ) : null}
                  </Customer>
                  <DecoratorText>”</DecoratorText>
                </Person>
                <Quote>"{testimonial.quote}"</Quote>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Carrousel>
      </ContentWithPaddingXl>

      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </>
  );
};
