import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import { NextArrow, PreviousArrow } from "./Arrows";

const CompaniesSlider = styled(Slider)`
  display: flex !important;
  align-items: center;
  align-self: stretch;
  background-color: transparent;
`;

const Carrousel = ({ children, ...props}) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    initialSlide: 0,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    arrows: true,
    nextArrow: <NextArrow name="NextArrow" />,
    prevArrow: <PreviousArrow name="PreviousArrow"/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <CompaniesSlider
      {...settings}
      {...props}
    >
      {children}
    </CompaniesSlider>
  );
};

export default Carrousel;
