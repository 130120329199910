import { useContext } from 'react';
import PageContext from 'hooks/pageContext';
import { ReactComponent as DecorationBlobImage1  }  from 'images/blob/svg-decorator-blob.svg';
import { ReactComponent as DecorationBlobImage2  }  from 'images/blob/svg-decorator-blob2.svg';
import { ReactComponent as DecorationBlobImage3  }  from 'images/blob/svg-decorator-blob3.svg';

export const DecorationBlob = (props) => {
  const { type } = useContext(PageContext)

  if(type === 'companies'){
    return (<DecorationBlobImage1 {...props} />)
 } 
  if (type === 'colaborators') {
    return (<DecorationBlobImage2 {...props} />)
 } 
  if (type === 'partners') {
    return (<DecorationBlobImage3 {...props} />)
 } 

 return
}