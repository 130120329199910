import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import tw from "twin.macro";

export default () => {
  const [cookies, setCookie] = useCookies(['cookie-policy']);
  const [show, setShow] = useState(!cookies['cookie-policy']);

  const Container = tw.div`bg-white fixed bottom-0 left-0 right-0 z-50 w-full`
  const Content = tw.div`mx-auto py-4 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center flex-1`
  const Text = tw.p`text-black`
  const Button = tw.button`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-5 md:mt-0`

  const handleClose = () => {
    setCookie('cookie-policy', 'accepted', { path: '/', maxAge: 2592000 });
    setShow(false);
  }

  return (
    <>
      {show && (
        <Container className="cookie-policy">
          <Content>
            <Text>Nós utilizamos cookies em nosso site para dar a melhor experiência por lembrar suas preferências em suas diferentes visitas. Ao clicar em "Aceito", você consente o uso de todos os nossos cookies.</Text>
            <Button onClick={handleClose}>Aceito</Button>
          </Content>
        </Container>
      )}
    </>
  );
}
