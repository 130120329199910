import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const LeftControlButtonContainer = tw(SliderControlButtonContainer)`left-0`;
const RightControlButtonContainer = tw(SliderControlButtonContainer)`right-0`;



export const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <RightControlButtonContainer>
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </RightControlButtonContainer>
);
export const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <LeftControlButtonContainer>
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </LeftControlButtonContainer>
);