import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import SectionTitle from "components/texts/SectionTitle";
import { DecorationBlob } from 'components/misc/DecorationBlob'
import { GrayContainer } from "components/misc/Layouts";
import PageContext from "hooks/pageContext";

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/3 flex-shrink-0 relative flex items-center justify-center`;
const TextColumn = styled(Column)(props => [
  tw`md:w-1/3 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`max-w-xs`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const Steps = tw.ul`mt-12 mb-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = styled.div`
  ${tw`font-semibold text-4xl leading-none text-white flex items-center justify-center`}
  background: linear-gradient(180deg, #088ABE 0%, #12C8AE 100%);
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  line-height: 60px;
`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
// const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`max-w-xs leading-loose text-sm text-gray-600 font-medium`;

const DecoratorBlob2 = styled(DecorationBlob)`
  ${tw`pointer-events-none absolute overflow-hidden left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  lineDescription = "",
  imageSrc = TeamIllustrationSrc,
  imageAlt= "ilustracao de time",
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const { firstStepsSection, secondStepsSection } = useContext(PageContext)

  if (!steps) steps = firstStepsSection;

  return (
    <GrayContainer>
      <TwoColumn>
        <TextColumn textOnLeft>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <SectionTitle heading={heading} lineDescription={lineDescription} />
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString()}</StepNumber>
                  <StepText>
                    {/* <StepHeading>{step.heading}</StepHeading> */}
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <Image src={imageSrc} alt={imageAlt} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={false}>
          <TextContent>
            <Steps>
              {secondStepsSection.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index + steps.length + 1).toString()}</StepNumber>
                  <StepText>
                    {/* <StepHeading>{step.heading}</StepHeading> */}
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <DecoratorBlob2 />
    </GrayContainer>
  );
};
