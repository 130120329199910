/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeadingThin } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts";
import LineDescription from "components/texts/LineDescription";
import PageContext from "hooks/pageContext";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { apiV2 } from "services/apiV2";
import { DropDown } from './components/DropDown'
import { CircularProgress } from '@mui/material'
import ReactInputMask from "react-input-mask";

// const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/2 h-auto flex-shrink-0 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-10 sm:mt-8 md:mt-0 lg:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 xl:ml-20 md:order-last`
]);

const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(LineDescription)`font-bold `;
const Heading = tw(SectionHeadingThin)`text-left md:text-left`;
const Description = tw.p`mt-6 text-left text-lg sm:text-lg md:text-lg lg:text-lg font-medium leading-relaxed text-secondary-100`
const Text = tw.p`mt-3`

const Form = tw.form`text-sm flex flex-col mx-auto md:mx-0`

const Input = styled.input`
  ${tw`mt-2 first:mt-0 border-b-2 py-3 px-3 focus:outline-none font-medium transition duration-300 shadow-input rounded-lg`};
  ${({error}) => error && tw`border-red-500`}
  /* &:focus, &:hover {
    border-color: ${({toggle}) => toggle}
  } */
`
const Label = tw.label`p-2 text-sm text-red-500`

const InputMessage = styled.textarea`
  ${tw`mt-2 first:mt-0 border-b-2 py-6 px-4 focus:outline-none font-medium transition duration-300 shadow-input rounded-lg resize-none`};
`

const SuccessMessage = tw.label`p-4 mt-2 bg-green-500 text-white rounded text-sm font-bold`
// const Textarea = styled(Input).attrs({as: "textarea"})`
//   ${tw`h-24`}
// `

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const companyValidationSchema = yup.object().shape({
  email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
  name: yup.string().required('Preencha o nome do responsável'),
  company: yup.string().required('Informe o nome da sua empresa'),
  numemployees: yup.string().required('Selecione uma opção'),
  jobtitle: yup.string().required('Informe o cargo'),
  state: yup.string().required('Escolha um Estado')
})

const partnerValidationSchema = yup.object().shape({
  email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
  name: yup.string().required('Preencha o nome do responsável'),
  company: yup.string().required('Informe o nome da sua empresa'),
  state: yup.string().required('Escolha um Estado'),
  city: yup.string().required('Digite uma cidade'),
})

const numemployeesList = ['100-500', '500-1000', '1000+']

const states = [
   'AC',
   'AL',
   'AP',
   'AM',
   'BA',
   'CE',
   'DF',
   'ES',
   'GO',
   'MA',
   'MT',
   'MS',
   'MG',
   'PA',
   'PB',
   'PR',
   'PE',
   'PI',
   'RJ',
   'RN',
   'RS',
   'RO',
   'RR',
   'SC',
   'SP',
   'SE',
   'TO'
]

export default ({
  subheading = "",
  heading = "Fale com a gente e",
  heading2 = "para sua empresa",
  submitButtonText = "Enviar",
  textOnLeft = true,
  lineDescription = "leve a Medipreço",
  description = <>
  <Text>Preencha o formulário ao lado. </Text>
  <Text>Nossa equipe entrará em contato com você e apresentará uma proposta exclusiva.</Text>
  <Text>Faça parte da inovação na saúde e leve este benefício para sua empresa!</Text>
  </>
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const {color, textColor, type} = useContext(PageContext)
  const [successMessage, setSuccessMassage] = useState('')
  const [placeholders, setPlaceholders] = useState({})

  const isCompanyOrCol = type === 'companies' || type === 'colaborators'

  const companyPlaceholders = {
    company: 'Nome da empresa',
    name: 'Nome completo do responsável por benefícios',
    email: 'E-mail do responsável por benefícios',
    phone: 'Telefone do responsável por benefícios (Opcional)',
    state: 'Estado da empresa'
  }

  const partnerPlaceholders = {
    company: 'Nome da sua empresa',
    name: 'Nome completo do responsável',
    email: 'E-mail do responsável',
    phone: 'Telefone do responsável (Opcional)',
    state: 'Estado de atuação'
  }

  const formik = useFormik({
    onSubmit: async (values) => {

      const name = values.name.split(' ')
      const jobtitle = values.jobtitle
      const email = values.email
      const phone = values.phone
      const company = values.company
      const numemployees = values.numemployees
      const message = values.message
      const state = values.state
      const city = values.city

      let request = 'users/company-lead-v2'
      let payload = {
        firstname: name[0],
        lastname: name[name.length - 1],
        jobtitle,
        email,
        phone,
        company,
        numemployees,
        message,
        state
      }
      if (type === 'partners') {
        request = 'users/supplier-lead'
        payload.name = values.name
        payload.company_name = company
        payload.city = city
      }

      payload.user_id = '5646'
      payload.email_from = 'Site Medipreço'

      const res = await apiV2.post(request, payload)
      .catch((error) => alert(error))

      setSuccessMassage('Parabéns! Entraremos em contato com sua empresa para que ela possa ter os benefícios Medipreço!')
      return res.data
    },
    initialValues: {
      email: '',
      name: '',
      phone: '',
      company: '',
      jobtitle: '',
      numemployees: '',
      message: '',
      state: '',
      city: ''
    },
    validationSchema: isCompanyOrCol ? companyValidationSchema : partnerValidationSchema
  })

  useEffect(() => {
    if (isCompanyOrCol) {
      setPlaceholders(companyPlaceholders)
    } else {
      setPlaceholders(partnerPlaceholders)
    }
  }, [type])

  return (
    <Container id="formsection">
      <Container>
        <TwoColumn>
          <ImageColumn>
          {heading && <Heading>{heading}</Heading>}
          {lineDescription && <Subheading color={color}>{lineDescription}</Subheading>}
          {heading2 && <Heading>{heading2}</Heading>}
            <Description>{description}</Description>
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Form onSubmit={formik.handleSubmit}>
                <Input 
                  type="text" 
                  name="company" 
                  placeholder={placeholders.company} 
                  error={formik.touched.company && formik.errors.company}
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Label>{formik.errors.company}</Label>
                <Input 
                  type="text"
                  name="name" 
                  placeholder={placeholders.name}
                  error={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Label>{formik.errors.name}</Label>
                {isCompanyOrCol && (
                  <>
                    <Input 
                      type="text" name="jobtitle" 
                      placeholder="Cargo do responśavel por benefícios"
                      error={formik.touched.jobtitle && formik.errors.jobtitle}
                      value={formik.values.jobtitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label>{formik.errors.jobtitle}</Label>
                  </>
                )}
                <Input 
                  type="email" 
                  name="email" 
                  placeholder={placeholders.email} 
                  error={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Label>{formik.errors.email}</Label>
                <Input
                  as={ReactInputMask} 
                  mask='(99) 99999-9999'
                  type="text" 
                  name="phone" 
                  placeholder={placeholders.phone} 
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Label>{formik.errors.phone}</Label>
                <DropDown
                  data={states}
                  name="state" 
                  placeholder={placeholders.state} 
                  error={formik.touched.state && !formik.values.state && formik.errors.state}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Label>{formik.errors.state}</Label>
                {type === 'partners' && (
                  <>
                    <Input 
                      type="text" name="city" 
                      placeholder="Cidade de atuação"
                      error={formik.touched.state && !formik.values.state && formik.errors.state}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label>{formik.errors.city}</Label>
                  </>
                )}
                {isCompanyOrCol && (
                  <>
                    <DropDown
                      data={numemployeesList}
                      name="numemployees" 
                      placeholder="Nº de funcionários" 
                      error={formik.touched.numemployees && !formik.values.numemployees && formik.errors.numemployees}
                      value={formik.values.numemployees}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label>{formik.errors.numemployees}</Label>
                    <InputMessage
                      type="text" 
                      name="message" 
                      placeholder="Mensagem (Opcional)" 
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
                <SubmitButton toggle={textColor} type="submit" disabled={!formik.isValid || formik.isSubmitting} >
                  {formik.isSubmitting ? <CircularProgress color="inherit" size={25}/> : `${submitButtonText}`}
                </SubmitButton>
                {successMessage ? (
                  <SuccessMessage>{successMessage}</SuccessMessage>
                ) : null}
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Container>
  );
};
