import React, { useState } from 'react';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import tw from 'twin.macro';
import Header from './light';

const StyledHeader = styled(Header)`
  ${tw`p-2 md:p-4 max-w-none absolute`}
`;

export default function Layout({ ...props }) {
  const [isSticky, setIsSticky] = useState(false);
  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else if (status.status === Sticky.STATUS_ORIGINAL) {
      setIsSticky(false);
    }
  };

  return (
    <Sticky innerZ={1001} top={0} onStateChange={handleStateChange}>
      <StyledHeader sticky={isSticky} {...props} />
    </Sticky>
  );
}
