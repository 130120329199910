import axios from 'axios';
import token from './token';

export const apiV2 = axios.create({
  baseURL: 'https://apiv2.medipreco.com.br'
})

apiV2.interceptors.request.use(async config => ({
  ...config,
  headers: {
    Authorization: token,
    ...config.headers
  }
}))
