import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import { DecorationBlob } from '../misc/DecorationBlob'
import { batimento, calendario, compras, dinheiro, linechart, locker, suporte, world } from 'images/features'
import SectionTitle from "components/texts/SectionTitle";
import Carrousel from "components/carrousel";
import { useContext } from "react";
import PageContext from 'hooks/pageContext';
import { useScrollOfHeaderButton } from "helpers/ScrollToTop";

const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;

const Column = styled.div`
  ${tw`lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(DecorationBlob)`
  ${tw`pointer-events-none absolute overflow-hidden z-1 right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const HeaderContainer = tw.div`flex items-center flex-col`

export default ({
  cards = [
    {
      imageSrc: suporte,
      title: "Atendimento 100% humanizado",
      description: "feito por profissionais de saúde",
      url: "formsection",
      alt: "suporte"
    },
    {
      imageSrc: batimento,
      title: "Acesso fácil",
      description: "a tratamentos e educação em saúde",
      url: "formsection",
      alt: "batimento"
    },
    {
      imageSrc: compras,
      title: "Aplicativo completo",
      description: "para sua saúde, com compra de um jeito simples e intuitivo",
      url: "formsection",
      alt: "compras"
    },
    {
      imageSrc: calendario,
      title: "Compra agendada",
      description: "para programar e receber os pedidos de forma recorrente",
      url: "formsection",
      alt: "calendario"
    },
    {
      imageSrc: dinheiro,
      title: "Várias formas de pagamento",
      description: "cartão de crédito, Pix e desconto em folha",
      url: "formsection",
      alt: "dinheiro"
    },
    {
      imageSrc: locker,
      title: "Entrega grátis e facilitada",
      description: "com armários inteligentes instalados na empresa",
      url: "formsection",
      alt: "locker"
    },
    {
      imageSrc: linechart,
      title: "Inteligência de dados em saúde",
      description: "com acesso a todas as informações estratégicas: dashboards, indicadores, relatórios epidemiológicos e muito mais",
      url: "formsection",
      alt: "grafico"
    },
    {
      imageSrc: world,
      title: "Parceiros em todo o Brasil",
      description: "que sempre oferecem o melhor preço para seus colaboradores",
      url: "formsection",
      alt: "mundo"
    }
  ],
  linkText = "",
  heading = "",
  subheading = "",
  description = "",
  lineDescription = "",
  imageContainerCss = null,
  imageCss = null,
}) => {
  const { textColor } = useContext(PageContext);

  const colorCardTitle = { color: textColor }
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  const ScrollToFormSection = useScrollOfHeaderButton('formsection')

  return (
    <Container id="howWeAct">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <SectionTitle heading={heading} lineDescription={lineDescription} />
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <Carrousel>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card onClick={ScrollToFormSection}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt={card.alt} css={imageCss} />
                </span>
                <span className="title" style={colorCardTitle}>{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </Carrousel>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
