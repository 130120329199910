import tw from "twin.macro";
import styled from "styled-components";

export const Header = styled.div`
  width: fit-content;
 ${tw`flex items-center min-w-fc mx-auto mb-2 pt-40 mt-20`}
`

export const HeaderTitle = tw.h1`text-3xl font-bold text-white`

export const BackGround = styled.div`
  ${tw`relative -mx-8 -mt-32 flex-1`}
  background: ${({toggle}) => toggle };
`;