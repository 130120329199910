import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { easeQuadInOut } from "d3-ease";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const RoudedGraph = ({ value }) => (
  <AnimatedProgressProvider
    valueStart={0}
    valueEnd={value}
    duration={1.4}
    easingFunction={easeQuadInOut}
  >
    {value => {
      const roundedValue = Math.round(value);
      return (
        <CircularProgressbar
          value={value}
          text={`${roundedValue}%`}
          /* This is important to include, because if you're fully managing the
    animation yourself, you'll want to disable the CSS animation. */
          styles={buildStyles({ pathTransition: "none" })}
        />
      );
    }}
  </AnimatedProgressProvider>
)

export default RoudedGraph
