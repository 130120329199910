import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
// import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/ThreeColSimple";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/gifs/gifcompra.gif";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import CarrouselList from "components/companies/CarrouselList";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import ThreeColumnWithProfileImage from "components/testimonials/ThreeColumnWithProfileImage";
import GraphsWithSideText from "components/features/GraphsWithSideText";
import { NavLink, NavLinks } from "components/headers/light.js";
import Header from "components/headers/stickyHeader";
import { Link } from "react-router-dom";
import PageContext from 'hooks/pageContext';
// import { handleBlogPage } from "helpers/ScrollToTop";
import { ButtonLink } from "components/buttons/index.js";
import { GrayContainer, GeralConteiner } from "components/misc/Layouts";
import YoutubeComponent from "components/YoutubeComponent";
import { PDFComponent } from "components/PDFComponent";
import TwoPlansWithDurationSwitcher from "components/pricing/TwoPlansWithDurationSwitcher";

export default () => {
  const Text = tw.p`mt-3`
  const navigate = useNavigate()
  const  { type, textColor, noHoverColor, benefitsSection, redirecionar } = useContext(PageContext);
  const handleAppPage = () => {
    redirecionar()
    navigate("/app")
  }

  const navLinks = [
    <NavLinks key={1}>
      <NavLink selected={type === 'companies'} toggle={textColor} noHover={noHoverColor}>
        <Link to="/">Para Empresas</Link>
      </NavLink>
      <NavLink selected={type === 'colaborators'} toggle={textColor} noHover={noHoverColor}>
        <Link to="/colaboradores">Para Colaboradores</Link>
      </NavLink>
      <NavLink selected={type === 'partners'} toggle={textColor} noHover={noHoverColor}>
        <Link to="/parceiros">Para Parceiros</Link>
      </NavLink>
      {/* <NavLink toggle={textColor} noHover={noHoverColor} onClick={handleBlogPage}>
        Blog
      </NavLink> */}
      <ButtonLink onClick={handleAppPage}>
        Baixe o App
      </ButtonLink>
    </NavLinks>
  ];

  let title = "Nossos"
  let subTitle = "Parceiros"
  if (type === 'partners') {
    title = "Empresas que"
    subTitle = "confiam na Medi"
  }

  let headerTitle = 'Termos de Uso'
  let url = 'https://s3.amazonaws.com/docs-customer.medipreco.com.br/medipreco_termos_de_uso.pdf'
  if(type === 'privacy-policy') {
    headerTitle = 'Política de Privacidade'
    url = 'https://s3.amazonaws.com/docs-customer.medipreco.com.br/medipreco_politica_de_tratamento_de_dados.pdf'
  } 

  return (
    <>
      {type === 'privacy-policy' || type === 'user-agreement' ? (
        <GeralConteiner>
          <Header links={navLinks} />
          <AnimationRevealPage>
            <PDFComponent url={url} title={headerTitle} bgColor={textColor}/>
          </AnimationRevealPage>
          <AnimationRevealPage>
            <Footer />
          </AnimationRevealPage>
        </GeralConteiner>
      ) : 
      type === 'app' || type === 'app-delivery' ? (
        <GeralConteiner>
          <Header links={navLinks} />
          <AnimationRevealPage>
            <Hero 
              roundedHeaderButton={true}
            />
          </AnimationRevealPage>
          <AnimationRevealPage>
            <Footer />
          </AnimationRevealPage>
        </GeralConteiner>
      ) : (
      <GeralConteiner>
        <Header links={navLinks} />
        <AnimationRevealPage>
          <Hero 
            roundedHeaderButton={true}
          />
          {type === 'companies' ? 
            (
              <CarrouselList title='Nossos' subTitle='Parceiros'/>
            ) : (
              <GrayContainer>
                <CarrouselList title={title} subTitle={subTitle}/>
              </GrayContainer>
            )}
          {type === 'companies' && <GraphsWithSideText />}
          <MainFeature2
            heading={benefitsSection.heading}
            lineDescription={benefitsSection.lineDescription}
            cards={benefitsSection.cards}
            imageSrc={prototypeIllustrationImageSrc}
            showDecoratorBlob={false}
          />
          <FeatureWithSteps
            heading="Veja como funcionamos"
            lineDescription="na prática"
            textOnLeft
            imageSrc={macHeroScreenshotImageSrc}
            imageDecoratorBlob={true}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
          />
          {type === 'companies' &&
            <TwoPlansWithDurationSwitcher />
          }
          <YoutubeComponent 
            heading="Veja alguns exemplos"
            lineDescription="na prática"
          />
          {type === 'companies' || type === 'colaborators' ? <TwoColContactUsWithIllustrationFullForm textOnLeft={false} /> : null }
          {type === 'partners' ? (
            <GrayContainer>
              <TwoColContactUsWithIllustrationFullForm
                textOnLeft={false} 
                heading2='parceiro Medipreço'
                lineDescription="seja um"
                description={
                  <>
                    <Text>Preencha o formulário ao lado. </Text>
                    <Text>Nossa equipe entrará em contato com você e apresentará uma proposta exclusiva.</Text>
                  </>
                }
              />
            </GrayContainer>
          ) : null}
          {type === 'companies' &&
            <GrayContainer>
              <ThreeColumnWithProfileImage />
            </GrayContainer>
          }
          {type === 'companies' ? (
            // <GrayContainer>
              <GetStarted />
            // </GrayContainer>
          ) : (
            <GetStarted/>
          )}
          <Footer />
        </AnimationRevealPage>
      </GeralConteiner>
      )}
    </>
  );
}
