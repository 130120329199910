import styled from "styled-components";
import tw from "twin.macro";
import { Field } from 'formik'

const Select = styled(Field).attrs({as: 'select'})`
${tw`mt-2 first:mt-0 border-b-2 py-3 px-3 focus:outline-none font-medium transition duration-300 shadow-input rounded-lg bg-white`};
${({error}) => error && tw`border-red-500`}
${({value}) => !value && tw`text-gray-400`}
`
export const DropDown = ({data, placeholder, ...props}) => {
  return (
    <Select {...props}>
      <option value="" disabled selected>{placeholder}</option>
      {data.map((item, index) => (
        <option key={index} value={item}>{item}</option>
      ))}
    </Select>
  )
}