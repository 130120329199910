import React, {useContext} from "react";
import tw from "twin.macro";
import { SectionHeadingThin } from "components/misc/Headings.js";
import LineDescription from "components/texts/LineDescription.js";
import PageContext from 'hooks/pageContext';
const Heading = tw(SectionHeadingThin)`text-left`;
const SubDescription = tw(LineDescription)`font-bold`;
const DescriptionContainer = tw.div`flex items-center justify-center self-start`;
const Container = tw.div`flex items-start flex-col`;

export default ({
  heading = "",
  lineDescription = "",
}) => {
  const {textColor} = useContext(PageContext)
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {lineDescription && <DescriptionContainer><SubDescription color={textColor}>{lineDescription}</SubDescription></DescriptionContainer>}
    </Container>
  );
};
